/******* screen-medium.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-medium.less 2013-1-16 *******/
.desk {
  max-width: 94%;
}
@media only screen and (max-width: 879px) {
  #head {
    width: 94%;
    margin: 30px 3% 0;
  }
  .cb-layout2 #head {
    width: 94%;
    margin: 30px 3% 0;
  }
  #home {
    float: none;
    display: block;
    margin: 20px auto;
    position: static;
  }
  .cb-layout2 h1 {
    font-size: 30px;
    padding-bottom: 30px;
  }
}
.togglenavigation {
  right: 3%;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 3%;
  margin-left: 3%;
}
.area .part,
.area > .grid table {
  margin-right: 3%;
  margin-left: 3%;
  width: 94%;
}
.area .tiny {
  width: 44%;
}
.area > .slim {
  width: 50%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 6%;
  margin-left: 6%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 88%;
}
.area > .slim .tiny {
  width: 88%;
}
.south {
  width: 100%;
}
.south > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.south h2,
.south .foot {
  margin-right: 6%;
  margin-left: 6%;
}
.south .part,
.south > .grid table {
  margin-right: 6%;
  margin-left: 6%;
  width: 88%;
}
.south > .slim .part,
.south > .slim.grid table {
  width: 88%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
@media only screen and (max-width: 879px) {
  div.main div.edge {
    margin-left: 3%;
    margin-right: 3%;
  }
  div.main div.edge.wide {
    width: 94%;
  }
  div.main div.edge.slim {
    width: 44%;
  }
  div.side div.unit {
    width: 50%;
  }
  div.side div.unit div.head h2,
  div.side div.unit div.part {
    margin-left: 6%;
    margin-right: 6%;
  }
  div.side div.unit div.part {
    width: 88%;
  }
  div.side div.unit.edge {
    margin-left: 3%;
    margin-right: 3%;
    width: 44%;
  }
  div.base {
    padding-left: 0;
    margin-right: 0;
  }
  div.south {
    float: right;
    width: 50%;
  }
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/* scan-large.less 2013-1-16 */
.scan li,
.scan li > .cb-hybrid,
.scan span,
.same > .cb-hybrid {
  padding: 0 0.3em;
}
th.prev,
th.next {
  font-weight: normal;
}
.scan th.prev,
.scan td.prev {
  text-align: left;
}
.scan th.next,
.scan td.next {
  text-align: right;
}
.same,
.this .same,
.same > .cb-hybrid {
  background: #fb262d;
  color: #fff;
}
.same,
.mese .same > span {
  background-color: #fb262d;
}
.this .same,
.this .same > .cb-hybrid,
.mese.this .same > span {
  background-color: #ea040c;
}
.mese .same {
  background: none;
}
/******* module-filter-medium.less 2013-1-16 *******/
.cb-mobile-filter-items > .beta > span {
  width: 14.28571429%;
}
/*# sourceMappingURL=./screen-medium.css.map */